import React from 'react';
import { Formik, Field, Form } from 'formik';
import userService from '../../../API/user/Customer.service';
import partnerCreationValidationSchema from '../@types/partnerCreationValidationSchema';
import classNames from 'classnames';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
/*
import useAuth from '../../../hooks/useAuth';
*/

interface RegisterFormProps {
  remove: () => void;
}

export const RegisterForm: React.FC<RegisterFormProps> = ({ remove }) => {
  const navigate = useNavigate();

  const { setAuth } = useAuth();

  const createCustomer = async (values: any) => {
    await userService.register(values).then((res) => {
      setAuth(res.data.access_token);
      navigate('/dashboard');
    });
  };
  return (
    <div className="bg-white py-8 px-4 shadow sm:px-10">
      <Formik
        enableReinitialize={true}
        onSubmit={createCustomer}
        validationSchema={partnerCreationValidationSchema}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          nbSiret: '',
          password: '',
          confirmPassword: '',
          legalName: ''
        }}>
        {({ errors, touched }) => (
          <Form className="h-full w-full flex-row">
            <div className="flex gap-x-2 mobile:flex-col mobile:gap-y-2 mb-2">
              <div className="flex flex-col w-full">
                <label
                  htmlFor="firstName"
                  className={classNames(
                    errors.nbSiret && touched.nbSiret && 'text-red-500 opacity-100',
                    'mb-1 font-lato text-xs opacity-50'
                  )}>
                  Numero de siret
                </label>
                <Field
                  id="nbSiret"
                  name="nbSiret"
                  placeholder="345678987654"
                  type="text"
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                />
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="lastName" className="mb-1 font-lato text-xs opacity-50">
                  Nom legal
                </label>
                <Field
                  id="legalName"
                  name="legalName"
                  placeholder="Codress"
                  type="text"
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                />
              </div>
            </div>
            <div className="flex gap-x-2 mobile:flex-col mobile:gap-y-2">
              <div className="flex flex-col w-full">
                <label htmlFor="firstName" className="mb-1 font-lato text-xs opacity-50">
                  Prénom
                </label>
                <Field
                  id="firstName"
                  name="firstName"
                  placeholder="Jane"
                  type="text"
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                />
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="lastName" className="mb-1 font-lato text-xs opacity-50">
                  Nom
                </label>
                <Field
                  id="lastName"
                  name="lastName"
                  placeholder="Jane"
                  type="text"
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-2 flex gap-x-2 mobile:flex-col mobile:gap-y-2">
              <div className="flex flex-col w-full mobile:w-full">
                <label htmlFor="email" className="mb-1 font-lato text-xs opacity-50">
                  Email
                </label>
                <Field
                  id="email"
                  name="email"
                  placeholder="jane@exemple.com"
                  type="email"
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                />
              </div>
            </div>
            <div className="flex gap-x-2 mt-2 mobile:gap-y-2 mobile:flex-col">
              <div className="flex flex-col w-full">
                <label htmlFor="password" className="mb-1 font-lato text-xs opacity-50">
                  Mot de passe
                </label>
                <Field
                  id="password"
                  name="password"
                  placeholder="Mot de passe"
                  type="password"
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                />
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="confirmPassword" className="mb-1 font-lato text-xs opacity-50">
                  Mot de passe confirmation
                </label>
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Mot de passe"
                  type="password"
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"></Field>
              </div>
            </div>
            <button
              type="submit"
              className="group mt-3 h-[49px] relative w-full flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-black hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
              Creer votre compte
            </button>
          </Form>
        )}
      </Formik>
      <div className="">
        <div className="relative p-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
        </div>
        <div className=" flex w-full justify-center items-center">
          <div>
            <button
              onClick={() => remove()}
              className="w-full flex items-center justify-center px-8 py-3 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
              Revenir à la Connexion
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
