import axios from 'axios';
const base_url = process.env.REACT_APP_DEVELOP_API;

const userService = {
  async login(data: { email: string; password: string }) {
    return axios.post(`${base_url}/auth`, data);
  },
  async getUserId(token: string) {
    return axios.get(`${base_url}/auth/profile`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  },
  async getUser(token: string, userId: string) {
    return axios.get(`${base_url}/auth/user/${userId}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  },

  async register(data: {
    firstName: string;
    lastName: string;
    email: string;
    nbSiret: string;
    password: string;
    confirmPassword: string;
    legalName: string;
  }) {
    return axios.post(`${base_url}/auth/register`, data);
  }
};
export default userService;
