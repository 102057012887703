import React from 'react';

export const CartIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.0849 15.7073L5.76631 5.70108H19.2336L19.915 15.7073C20.0769 18.0841 18.1925 20.1011 15.8103 20.1011H9.18967C6.80738 20.1011 4.92304 18.0841 5.0849 15.7073Z"
        stroke="currentColor"
        strokeWidth="1.4"
        shapeRendering="crispEdges"
      />
      <path
        d="M8.89997 4.50108C8.89997 2.51286 10.5117 0.901085 12.5 0.901085C14.4882 0.901085 16.1 2.51286 16.1 4.50109V6.90109C16.1 8.88931 14.4882 10.5011 12.5 10.5011C10.5117 10.5011 8.89997 8.88931 8.89997 6.90109V4.50108Z"
        stroke="currentColor"
        strokeWidth="1.4"
        shapeRendering="crispEdges"
      />
    </svg>
  );
};
