import * as Yup from 'yup';

const partnerCreationValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Le prénom est requis'),
  lastName: Yup.string().required('Le nom de famille est requis'),
  email: Yup.string().email("Format d'e-mail invalide").required("L'e-mail est requis"),
  nbSiret: Yup.string().required('Le numéro SIRET est requis').min(14).max(14),
  password: Yup.string().required('Le mot de passe est requis'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Les mots de passe doivent correspondre')
    .required('La confirmation du mot de passe est requise'),
  legalName: Yup.string().required('Le nom légal est requis')
});

export default partnerCreationValidationSchema;
