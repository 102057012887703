import { gql } from 'graphql-tag';

export const CUSTOMER_ACCESS_TOKEN_CREATE = gql`
  mutation customerAccessTokenCreate($email: String!, $password: String!) {
    customerAccessTokenCreate(input: { email: $email, password: $password }) {
      customerAccessToken {
        accessToken
      }
      customerUserErrors {
        message
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query GetCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      email
      firstName
      id
      lastName
      phone
      acceptsMarketing
      metafield(namespace: "global", key: "stripeCustomerId") {
        key
        type
        value
      }
      metafields(identifiers: { namespace: "global", key: "shippingCount" }) {
        key
        type
        value
      }
      defaultAddress {
        address1
        phone
        zip
        city
        id
        countryCode
        provinceCode
        country
      }
    }
  }
`;

export const GET_SHOP = gql`
  query Shop {
    shop {
      name
      brand {
        colors {
          primary {
            background
            foreground
          }
          secondary {
            background
            foreground
          }
        }
        logo {
          alt
          image {
            altText
            url
          }
        }
      }
    }
  }
`;

export const CUSTOMER_CREATE = gql`
  mutation CustomerCreate(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $acceptsMarketing: Boolean!
  ) {
    customerCreate(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        acceptsMarketing: $acceptsMarketing
      }
    ) {
      customer {
        email
        firstName
        id
        lastName
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const CUSTOMER_RECOVER = gql`
  mutation CustomerRecover($email: String!) {
    customerRecover(email: $email) {
      userErrors {
        field
        message
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const CUSTOMER_ADDRESS_UPDATE = gql`
  mutation CustomerAddressUpdate(
    $customerAccessToken: String!
    $id: ID!
    $address1: String!
    $city: String!
    $country: String!
    $phone: String!
    $zip: String!
  ) {
    customerAddressUpdate(
      customerAccessToken: $customerAccessToken
      id: $id
      address: {
        address1: $address1
        city: $city
        country: $country
        phone: $phone
        zip: $zip
        province: null
      }
    ) {
      customerAddress {
        address1
        city
        country
        countryCode
        firstName
        id
        lastName
        name
        phone
        zip
      }
      userErrors {
        field
        message
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const CUSTOMER_UPDATE = gql`
  mutation CustomerUpdate(
    $customerAccessToken: String!
    $acceptsMarketing: Boolean!
    $phone: String!
    $lastName: String!
    $firstName: String!
  ) {
    customerUpdate(
      customerAccessToken: $customerAccessToken
      customer: {
        acceptsMarketing: $acceptsMarketing
        phone: $phone
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      customer {
        firstName
        id
        lastName
        phone
      }
      userErrors {
        field
        message
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;
